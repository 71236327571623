import React from 'react'
import Layout from '../components/Layout'

const Github = () => {
    return (
        <Layout>    
            <section>
                <div className="container">
                    <h2>GitHub Projects</h2>
                </div>
            
            </section>
        </Layout>
    )
}

export default Github
