import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import * as footerStyles from './footer.module.css'
// import Head from '../components/head'

const Footer = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    company
                }
            }
        }
    `)
    return (
        <footer className={footerStyles.footer}>    
            <p> Designed & created by {data.site.siteMetadata.title}, &copy; 2024 </p>
            <p> Copyright &copy; 2021 { data.site.siteMetadata.company } </p>
        </footer>
    )
}

export default Footer
