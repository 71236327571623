import {  Link, graphql, useStaticQuery } from 'gatsby'
import React from 'react'

const Navbar = () => {

    const data = useStaticQuery(graphql`
          query SiteInfo {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    const { title } = data.site.siteMetadata

    return (
        <nav>  
            <h1>{ title } </h1>
                <div className="links">
                    <Link to="/">Home                       </Link>
                    <Link to="/resume">Resume               </Link>
                    <Link to="/about">About                 </Link>
                    <Link to="/github">GitHub Repo          </Link>
                    <Link to="/projects">Portfolio Projects </Link>
                </div>
        </nav>
    )
}

export default Navbar
